























import Vue from 'vue';
import Article from '@/components/ArticleItem.vue';
import Recommended from '@/components/Recommended.vue';

export default Vue.extend({
	components: { Article, Recommended },
	data () {
		return {
			articles: []
		};
	},
	beforeMount () {
		this.fetchArticles();
	},
	watch: {
		'$route.params.term' () {
			this.fetchArticles();
		}
	},
	methods: {
		fetchArticles () {
			this.$store.dispatch('fetch', {
				params: { term: this.$route.params.term },
				fragment: `article/search/`,
				endpoint: 'content'
			}).then(res => { this.articles = res.data; });
		}
	}
});
